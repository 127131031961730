import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Scrollbars } from 'react-custom-scrollbars';
import ContextProvider from "./context/Context"
import swDev from "./swDev";
import {
  BrowserRouter as Router,
} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode  style={{ "backgroundColor": "red" }}>
    <ContextProvider>
      <Router>
        <Scrollbars style={{ width: "100%", height: "100vh", color: "black", zIndex: "1" }}>
          <App />
        </Scrollbars>
      </Router>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();
