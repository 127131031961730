import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import appBarLogo from "../images/appBarLogo.png"
import { GlobalContext } from '../context/Context';
import { useHistory } from "react-router-dom";

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: "2px dotted orange"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    // backgroundColor: "#FCEDDA"
  },
  logoDiv: {

    // flexGrow: 1,
    // border: "2px solid red",
    width: "200px",
    overflow: "hidden",
    height: "35px",
    marginLeft: "20px",
    // backgroundColor: "green",



    // height: "40px",
    // backgroundColor: "red"
  },
  
}));

export default function NavigationBar() {
  const classes = useStyles();

  let { state, dispatch } = useContext(GlobalContext);
  let history = useHistory();



  return (
    <AppBar position="fixed" className={classes.toolbar}>
      <Toolbar style={{ "justifyContent": "space-between" }}>
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
        {/* <Typography variant="h6" className={classes.title}>
              Logo here
            </Typography> */}
        <div className={classes.logoDiv} onClick={() => { history.push("/") }}>
          <img src={appBarLogo} style={{ height: "100%" }}></img>
          {/* <h4 style={{ width: "100%", height: "100%" }}>Logo</h4> */}
        </div>
        <IconButton
          size="medium"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => {
            dispatch({ type: "TOGGLE_MUTE" })
          }}
        >
          {(state.isMute) ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
