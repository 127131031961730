import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MicIcon from '@material-ui/icons/Mic';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from "axios";
import logo from '../images/logo.png';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { customAlphabet } from 'nanoid'
import { GlobalContext } from '../context/Context';



import { baseUrl } from "../core"
import "animate.css"


export default function ChatBox() {



  const [messages, setMessages] = useState([]);
  // const [serverMessages, setServerMessages] = useState([]);
  const [textField, setTextField] = useState("");
  const [isThinking, setIsThinking] = useState(false);
  const [isListening, setIsListening] = useState(false);
  let { state,
    // dispatch
  } = useContext(GlobalContext);



  let botMessageBubbleColor = state.darkTheme ? "#252D31" : "#EAECEE";
  let botMessageTextColor = state.darkTheme ? "white" : "black";

  let userMessageBubbleColor = state.darkTheme ? "#056062" : "#3F50B5";
  let userMessageTextColor = state.darkTheme ? "white" : "white";

  let inputBackgroundColor = state.darkTheme ? "#2C373E" : "white";
  let inputTextColor = state.darkTheme ? "#777F81" : "black";

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'right',
      color: theme.palette.text.secondary,
      borderRadius: "20%",
      backgroundColor: "#EAECEE"
    },
    container: {
      // border: "2px solid red",
      // minHeight: "89vh"
    },
    item: {
      // border: "2px solid black",
      padding: "0.2rem",

    },
    mainDiv: {
      paddingTop: "4rem",
      minHeight: "90vh",
      // border: "2px solid red",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    userMessageBox: {
      // overflowY: "hidden",
      // whiteSpace: "wrap",
      maxWidth: "40rem",
      borderRadius: "30px 0px 30px 30px", // optimised for large messages
      padding: "0.9rem",
      backgroundColor: userMessageBubbleColor,
      color: userMessageTextColor,
      fontSize: "1.1rem",
      marginTop: "2rem",
      marginBottom: "2rem",
      position: " relative",
      "&::before": {
        content: "''",
        width: "0px",
        height: "0px",
        position: "absolute",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        borderTop: `15px solid ${userMessageBubbleColor}`,
        borderBottom: "15px solid transparent",
        right: "-13px",
        top: "0px",
      }
    },
    serverMessageBox: {
      maxWidth: "40rem",
      borderRadius: "0px 30px 30px 30px", // optimised for large messages
      padding: "0.9rem",
      backgroundColor: botMessageBubbleColor,
      color: botMessageTextColor,
      fontSize: "1.1rem",
      position: " relative",
      "&::before": {
        content: "''",
        width: "0px",
        height: "0px",
        position: "absolute",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        borderTop: `15px solid ${botMessageBubbleColor}`,
        borderBottom: "15px solid transparent",
        left: "-13px",
        top: "0px",
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "1.5rem",
      },
      marginLeft: "0.2rem",
    },
    suggestionChip: {
      maxWidth: "40rem",
      alignSelf: "center",
      whiteSpace: "nowrap",
      border: "0.1rem solid #3E50B5",
      borderRadius: "30px 30px 30px 30px", // optimised for large messages
      padding: "0.9rem",
      backgroundColor: "#EAECEE",
      fontSize: "1.1rem",
      position: " relative",
      marginLeft: "0.5rem",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "1.5rem",
      },
    },
    suggestionImages: {
      maxWidth: "40rem",
      alignSelf: "center",
      whiteSpace: "nowrap",
      // borderRadius: "30px 30px 30px 30px", // optimised for large messages
      // padding: "0.9rem",
      // backgroundColor: "#EAECEE",
      fontSize: "1.1rem",
      position: " relative",
      margin: "0.6rem",
    },
    logo: {
      // border:"2px solid red",
      width: "51px",
      height: "51px",
      marginLeft: "1rem"
    },
    logoDiv: {

    },

  }));
  const classes = useStyles();




  const scrollRef = useRef(null);

  useEffect(() => {
    if (state.isMute) {
      stopSpeak()
    }
  }, [state.isMute]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }

    if (isListening) {
      // play start sound
    } else {
      // play end sound
    }


  }, [messages, textField, isListening]);

  useEffect(() => {
    if (!localStorage.getItem("uid")) {
      const nanoid = customAlphabet('1234567890abcdef', 10)
      localStorage.setItem("uid", nanoid())
    }
    send("Hi", true)

    // function regEvent(event) {
    //   if (event.code === "Space") {
    //     // console.log("Space is pressed : ", onFocus, onBlur)
    //     console.log("isListening...", isListening)

    //     if (!textField.trim() && !isListening) {
    //       console.log("start Listening")
    //       runSpeechRecognition()
    //     }
    //   }
    // }
    // window.addEventListener("keypress", regEvent);
    // return () => {
    //   window.removeEventListener("keypress", regEvent)
    // }
  }, [])

  let audioPlayer;
  function speakOut(res) {
    if (res?.data?.outputAudio && !isListening && !state.isMute) {
      audioPlayer = document.querySelector("#audio");
      audioPlayer.pause()
      audioPlayer.currentTime = 0;
      audioPlayer.src = "data:audio/mp3;base64," + res.data.outputAudio;
      audioPlayer.play();
    }
  }
  function stopSpeak() {
    audioPlayer = document.querySelector("#audio");
    audioPlayer.pause()
    audioPlayer.currentTime = 0;
  }
  function doActions(actions) {
    actions?.map((eachAction, actionIndex) => {
      switch (eachAction?.action) {
        case "CHANGE_TABLE":
          localStorage.setItem("uid", eachAction?.payload)
          break;
      }
    })
  }

  function send(text, silently) {

    if (!silently) {
      setIsThinking(true)
      setMessages(previousValue => previousValue.concat([{ message: text, from: "user" }]))
    }
    axios({
      method: "post",
      url: `${baseUrl}/query`,
      data: {
        message: text,
        from: localStorage.getItem("uid")
      },
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache', // required for safari to behave normally
        'Expires': '0',
      }
    })
      .then((res) => {
        // console.log("response", res.data)
        if (!silently) {
          speakOut(res);
        }
        doActions(res?.data?.actions)
        setIsThinking(false)
        setMessages(previousValue => previousValue.concat([res.data]))
      })
      .catch((err) => {
        console.log("Error", err)
        console.log("Error", messages)

        setMessages(previousValue => previousValue.concat([{ from: "chatbot", messages: ["something went wrong, check internet connection"] }]))
        setIsThinking(false)

      })
  }

  // console.log('textfiel', textField)
  const handleSubmit = (e) => {
    e.preventDefault();
    send(textField)
    setTextField("")
  }
  const handleChange = (e) => {
    setTextField(e.target.value)
  }

  function runSpeechRecognition() {
    console.log("run");
    // new speech recognition object
    var SpeechRecognition = SpeechRecognition || window.SpeechRecognition || window.webkitSpeechRecognition;
    var recognition = new SpeechRecognition();

    console.log("recognition: ", recognition);

    recognition.onerror = function (e) {
      // setIsListening(() => false)
      // recognition.stop();

      console.log("error fired: ", e);

    }


    recognition.lang = "en-US";

    var grammar =
      `#JSGF V1.0; 
      grammar dishes;
      public <dish> = Halwa Puri | Aloo Tarkari | French Toast | Chicken Tandoori | Grilled Mutton Chops | Mutton Kabab | Beef Bihari Boti | Dhaga Kabab | Dal Makhani | Dal Maash | Dal Tadka | Mix Vegetable | Plain Steamed Rice | Grilled Prawns | Chili Garlic Prawns | Balochi Fish | Desi Murgh Karahi | Mutton Shinwari Karahi | Mutton Peshawari Karahi | Chicken Shinwari Karahi | Chicken Peshawari Karahi | Chicken Boneless Handi | Butter Chicken | Chicken Achari | Chicken Mirchi Qeema | Mutton Pulao | Mutton Biryani | Mutton Paya | Beef Paya | Chicken Haleem | Chicken Biryani | Kulfi Falooda | Shahi Rabdi | Gajar Ka Halwa | Mutton Nihari | Chicken Nihari | Beef Nihari;
      
      grammar categories;
      public <category> = Breakfast | BBQ | Veg | Non-Veg | Dessert | B&B Speciality ;
      
      grammar quantities;
      public <quantity> = one | two | three | four | five ;
      `
    var SpeechGrammarList = SpeechGrammarList || window.webkitSpeechGrammarList
    var speechRecognitionList = new SpeechGrammarList();
    speechRecognitionList.addFromString(grammar, 1);

    recognition.grammars = speechRecognitionList;

    // console.log("recognition.lang: ", recognition.lang)
    // console.log("recognition.grammars: ", recognition.grammars)

    // This runs when the speech recognition service starts
    recognition.onstart = function () {
      stopSpeak();
      setIsListening(() => true)
    };

    recognition.onabort = function () {
      // setIsListening(() => false)
      // recognition.stop();

      console.log("abort fired");

    }
    // This runs when the speech recognition service stops
    recognition.onspeechend = function () {
      setIsListening(() => false)
      recognition.stop();
    }

    // This runs when the speech recognition service returns result
    recognition.onresult = function (event) {
      console.log("result: ", event);
      var transcript = event.results[0][0].transcript;
      var confidence = event.results[0][0].confidence;

      if (transcript.toLowerCase() === "tu") {
        transcript = "two";
        // console.log("tu correction");
      }

      send(transcript)

    };
    // start recognition
    try {
      recognition.start();
    } catch (error) {
      console.log("speech error: ", error);
    }
  }

  return (
    <div className={classes.mainDiv}>

      <audio id="audio"></audio>

      {/* Messages container */}
      <Grid
        container className={classes.container}
        style={{ paddingBottom: '4.6rem' }}>

        <img src={logo} alt="" style={{ visibility: "hidden" }}
          className={classes.logo + " animate__animated animate__shakeY animate__infinite animate__delay-2s"} />

        <Grid item xs={12} className={classes.item}
          style={{
            display: "flex", justifyContent: "flex-start",
            textAlign: 'left', paddingLeft: "0.7rem"
          }} >

          {/* <div className={classes.serverMessageBox}>
            Good morning Well come to *Cafe name* how can I help you?
          </div> */}
        </Grid>

        {messages.map((eachMessage, messageIndex) => {
          return (
            (eachMessage?.from !== "chatbot") ?
              <Grid key={`${messageIndex}-user`}
                ref={scrollRef}
                item xs={12}
                className={classes.item}
                style={{
                  display: "flex", justifyContent: "flex-end",
                  textAlign: 'right', paddingRight: "1.5rem"
                }} >
                <div className={classes.userMessageBox}>
                  {eachMessage.message}
                </div>
                {/* <p className={classes.messageParagraph} style={{}}>
                  {eachMessage.message}
                </p> */}
              </Grid>
              :
              [
                // text
                (eachMessage?.messages?.length) ?
                  eachMessage?.messages.map((eachText, textIndex) => (
                    <Grid key={textIndex + "chatbot"}
                      ref={scrollRef} item xs={12}
                      className={classes.item}
                      style={{
                        display: "flex", justifyContent: "flex-start",
                        paddingLeft: "0.7rem"
                      }} >

                      <div className={classes.serverMessageBox}>
                        {eachText}
                      </div>

                    </Grid>
                  )) : null,

                // Images
                (eachMessage?.images?.length) ?
                  <Grid
                    key={`${messageIndex}-chatbot-image`}
                    ref={scrollRef} item xs={12}
                    className={classes.item}
                    style={{
                      display: "flex", justifyContent: "flex-start",
                      textAlign: 'left', paddingLeft: "0.7rem",
                      overflowX: "scroll"
                    }} >
                    {eachMessage?.images?.map((eachImage, imageIndex) => (
                      <img width={700} key={`${imageIndex}-image`} src={eachImage} alt="" />
                    ))}

                  </Grid> : null,

                // cards
                (eachMessage?.cards?.length) ?
                  <Grid
                    key={`${messageIndex}-chatbot-image`}
                    ref={scrollRef} item xs={12}
                    className={classes.item}
                    style={{
                      display: "flex", justifyContent: "flex-start",
                      textAlign: 'left', paddingLeft: "0.7rem",
                      overflowX: "scroll"
                    }} >
                    {eachMessage?.cards?.map((eachCard, imageIndex) => (
                      <div key={`${imageIndex}-image`}
                        className={classes.suggestionImages}
                      >
                        {/* <img key={i} width="400px"
                          src={eachCard} alt=""
                        />
                        <Typography>some text</Typography> */}

                        <Card sx={{ width: 300 }}
                          onClick={(event) => { send(eachCard?.title) }}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={eachCard?.imageUri}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography variant="h6" component="div">
                                {eachCard?.title}
                              </Typography>

                              {(eachCard?.subtitle) ?
                                <Typography variant="body2" color="text.secondary">
                                  {eachCard?.subtitle}
                                </Typography>
                                : null}

                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    ))}

                  </Grid> : null,

                // Suggestions
                (eachMessage?.suggestions?.length) ?
                  <Grid
                    key={`${messageIndex}-chatbot-suggestion`}
                    ref={scrollRef} item xs={12}
                    className={classes.item}
                    style={{
                      display: "flex", justifyContent: "flex-start",
                      textAlign: 'center', paddingLeft: "0.7rem",
                      overflowX: "scroll"

                    }} >
                    {eachMessage?.suggestions?.map((eachSuggestion, suggestionIndex) => (
                      <div key={`${suggestionIndex}-suggestions`} onClick={() => { send(eachSuggestion) }} className={classes.suggestionChip}>
                        {eachSuggestion}
                      </div>
                    ))}
                  </Grid> : null
              ]

          )
        })}


        {/* TODO: please add this animation only on chatbot each new message: https://codepen.io/clemens/pen/kXZWOK */}
        <div style={{
          display: `${isThinking ? 'flex' : 'none'}`,
          justifyContent: 'flex-start',
          alignitems: "center",
          marginBottom: "0.45rem",
          marginLeft: "0.25rem"
        }} className="">

          <img
            src={logo}
            alt=""
            className={classes.logo + " animate__animated animate__shakeY animate__infinite animate__faster"} />

          <span style={{ border: "1px solid #EAECEE", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "2rem", paddingRight: "0.5rem", paddingLeft: "0.5rem", marginBottom: "0.5rem", marginTop: '0.5rem' }} className="span">
            <Brightness1Icon className="material-icon animate__animated animate__shakeY animate__infinite animate__faster"></Brightness1Icon>
            <Brightness1Icon className="material-icon animate__animated animate__shakeY animate__infinite animate__delay-2s animate__faster"></Brightness1Icon>
            <Brightness1Icon className="material-icon animate__animated animate__shakeY animate__infinite animate__delay-3s animate__faster"></Brightness1Icon>
          </span>

        </div>

      </Grid>

      {/* footer */}
      <form
        onSubmit={(e) => handleSubmit(e)}
        style={{
          position: "fixed",
          bottom: '0', width: "100%"
        }}>
        <Grid container style={{ borderTop: "1px solid #BFBFBF", backgroundColor: inputBackgroundColor }} >
          <Grid item xs={10} md={11} style={{ paddingLeft: "3rem", paddingTop: "1rem", paddingBottom: "1rem", paddingRight: '1rem' }}>
            <TextField
              required
              id="output"
              style={{ width: "100%", color: "red" }}
              value={textField}
              onChange={handleChange}
              placeholder={`Say "Hi" to Chatbot`}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                disableUnderline: true
              }}
            >
            </TextField>
          </Grid>
          <Grid item xs={2} md={1} style={{ display: "flex", justifyContent: "center", alignitems: "center" }} >
            <span id="action"></span>
            <Button id="form-button" style={{ width: "100%" }}>
              {textField.trim() ? <SendIcon onClick={(e) => handleSubmit(e)} style={{ color: "#3F51B5", fontSize: "2.3rem" }} /> : !isListening ? <MicIcon onClick={() => runSpeechRecognition()} style={{ color: "#3F51B5", fontSize: "2.3rem" }} /> : <p onClick={() => setIsListening(false)}>listening</p>}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div >
  );
}