import NavigationBar from "./components/NavigationBar";
import ChatBox from "./components/ChatBox";
import Orders from "./components/Orders";
import { Dictaphone } from "./components/Orders";
import "./App.css"

import {
  Switch,
  Route,
} from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { GlobalContext } from './context/Context';


function App() {
  let { state, dispatch } = useContext(GlobalContext);

  const chatBackgroundColor = state.darkTheme ? "#0D1419" : "white";








  useEffect(() => {

    // const listenForDarkMode = () => {
    //   if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     console.log("dark mode detected");
    //     dispatch({ type: "ACTIVATE_DARK_THEME" })
    //   } else {
    //     dispatch({ type: "DEACTIVATE_DARK_THEME" })
    //   }

    //   window.matchMedia('(prefers-color-scheme: dark)')
    //     .addEventListener('change', event => {
    //       if (event.matches) {
    //         dispatch({ type: "ACTIVATE_DARK_THEME" })
    //       } else {
    //         dispatch({ type: "DEACTIVATE_DARK_THEME" });
    //       }
    //     });
    // }

    const stopListenForDarkMode = () => {
      window.matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener("change")
    }
    // listenForDarkMode();
    return stopListenForDarkMode
  }, [])




  return (
    <div style={{ "backgroundColor": chatBackgroundColor }}>

      <Switch>
        <Route path="/orders">
          <>
            <Orders />
          </>
        </Route>

        <Route path="/">

          <>
            <NavigationBar></NavigationBar>
            <ChatBox></ChatBox>
          </>

        </Route>
      </Switch>



    </div>
  );
}

export default App;
