import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import io from "socket.io-client";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { baseUrl } from "../core"
import sound from "./../images/notification.mp3"

import moment from "moment"

export default function Orders() {
  const [orders, setOrders] = useState(null);
  const [update, setUpdate] = useState(false);
  const notificationRef = useRef();


  const scrollRef = useRef(null);


  useEffect(() => {
    axios
      .get(`${baseUrl}/api/v1/orders`)
      .then((resp) => {

        console.log("orders: ", resp?.data);

        setOrders(resp?.data)
      })
      .catch(console.log)
  }, [update])


  useEffect(() => {
    const socket = io(baseUrl);

    // Socket implementation
    socket.on("connect", () => {
      console.log("socket connected");
    });
    socket.on("disconnect", () => {
      console.log("socket disconnected");
    });
    socket.on("order", data => {
      console.log("realtime data: ", data);
      notificationRef.current.play();
      setUpdate(!update)
    })
    return () => {
      console.log("cleanup");
      socket.disconnect()
    }
  }, [])

  return (
    <div>

      <audio ref={notificationRef}>
        <source type="audio/mp3" src={sound} />
      </audio>

      {/* <button onClick={play}>play</button> */}

      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {
          orders?.map((eachOrder, orderIndex) => (
            <React.Fragment key={orderIndex} >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={eachOrder?.items[0]?.item?.cuisinePhotoUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`Table# ${eachOrder?.tableNumber}`}
                  secondary={[

                    eachOrder?.items?.map((eachItem, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        <Typography

                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {eachItem?.item?.cuisineTitle} -
                        </Typography> {eachItem?.item?.priceInAED} x {eachItem?.qty} Pcs. &nbsp; {eachItem?.item?.priceInAED * eachItem?.qty} AED
                        <br />
                      </React.Fragment>
                    )),
                    <React.Fragment key={"a1"}>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontWeight: "bold",
                          "borderTop": "1px solid #888",
                          "lineHeight": "2.5rem"
                        }}
                        component="span"
                        variant="body2"
                        color="text.primary">
                        Total: {
                          eachOrder?.items
                            .map((eachItem, eachItemInd) => {
                              return eachItem?.item?.priceInAED * eachItem?.qty
                            })
                            .reduce((a, b) => a + b, 0)
                        } AED
                      </Typography>

                    </React.Fragment>,
                    <br key={"a2"} />,
                    <React.Fragment key={"a3"}>{moment(eachOrder?.createdOn).fromNow()}</React.Fragment>
                  ]
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        }
      </List>

      {
        (orders && orders.length === null) ? "Loading orders..." : ""
      }
      {
        (orders && orders.length === 0) ? "No orders" : ""
      }

    </div >
  );
}



export const Dictaphone = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div>
  );
};
